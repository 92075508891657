<template>
    <div>
        <b-card title="" border-variant="primary">
            <form-base-document-inspections
                :documents-state-data="documents_maintenance_inspection"
                :documents-columns="columns"
                :documents-route="documents_by_route_maintenance_inspection"
                :context="'document-inspection'"
                :create-permission="can('create_vhe_master_maintenance_inspections')"
                :delete-permission="can('delete_vhe_master_maintenance_inspections')"
            />
        </b-card>
    </div>
</template>

<script>
import {BCard} from 'bootstrap-vue'
import {mapState} from "vuex"
import formBaseDocumentInspections
    from "@/views/vhe/masters_installations/form/tabMaintenance/components/base/formBaseInspections"

import {mapFields} from "vuex-map-fields"

export default {
    name: "FormContractProviderDocuments",
    components: {
        BCard,
        formBaseDocumentInspections
    },
    data() {
        return {
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'effective_date', label: 'Fecha Realización', sortable: false},
                {key: 'report_number', label: 'Nª Informe', sortable: false},
                {key: 'made_by', label: 'Realizado por', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
            ],
        }
    },
    computed: {
      ...mapState('masterInstallationVhe', ['master_installation']),
      ...mapState('masterInstallationDocumentsVhe', []),
        ...mapFields({
            documents_maintenance_inspection: 'masterInstallationDocumentsVhe.documents_maintenance_inspection',
            documents_by_route_maintenance_inspection: 'masterInstallationDocumentsVhe.documents_by_route_maintenance_inspection',
        }),
    },
    mounted() {
       this.context = 'document-inspection'
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
    }

}
</script>
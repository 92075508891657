<template>
    <div>
        <b-card title="" border-variant="primary">
            <div class="d-flex align-items-center justify-content-between mb-1">
                <h5 class="mb-0">Números de Serie</h5>
                <div>
                    <b-button
                        variant="outline-success"
                        @click="addRow"
                    >
                        <feather-icon
                            icon="PlusIcon"
                            size="16"
                            variant="success"
                            class="cursor-pointer"
                        />
                        Añadir nuevo número de serie
                    </b-button>
                </div>
            </div>

            <b-table
                :items="serial_numbers"
                :fields="columns"
                responsive
                sticky-header
                striped
                primary-key="id"
                show-empty
                editable
                empty-text="No hay registros"
            >
                <template #cell(serial_number)="{ item }">
                    <template v-if="isRowEditable(item)">
                        <b-form-input v-model="item.serial_number" autocomplete="off"/>
                    </template>
                    <template v-else>
                        {{ item.serial_number }}
                    </template>
                </template>
                <template #cell(observations)="{ item }">
                    <template v-if="isRowEditable(item)">
                        <b-form-input v-model="item.observations" autocomplete="off"/>
                    </template>
                    <template v-else>
                        {{ item.observations }}
                    </template>
                </template>

                <template #cell(options)="data">
                    <div class="d-flex align-items-center">
                        <feather-icon
                            :id="`record-row-${data.item.id}-edit-icon`"
                            :icon="getButtonIcon(data.item)"
                            class="cursor-pointer mr-1"
                            :class="getButtonVariant(data.item)"
                            size="16"
                            @click="editRow(data.item)"
                        />

                        <feather-icon
                            :id="`record-row-${data.item.id}-delete-icon`"
                            icon="TrashIcon"
                            size="16"
                            class="cursor-pointer text-red"
                            @click="deleteRecord(data.item.id, data.index )"
                        />

                    </div>
                </template>

            </b-table>
        </b-card>
    </div>
</template>

<script>
import {BCard, BButton, BTable, BFormInput} from 'bootstrap-vue'
import {mapState} from "vuex"
import {mapFields} from "vuex-map-fields"
import Swal from "sweetalert2"
import helper from "@/resources/helpers/helpers"


export default {
    name: "FormSerialNumbers",
    components: {
        BButton,
        BCard,
        BTable,
        BFormInput
    },
    data() {
        return {
            showLoading: false,
            editedRow: null,
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'serial_number', label: 'Nº Serie', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
            ],
        }
    },
    computed: {
        ...mapState('masterInstallationVhe', ['validation_master_installation']),
        ...mapFields({
            serial_numbers: 'masterInstallationVhe.master_installation.serial_numbers',
            master_installation: 'masterInstallationVhe.master_installation',
        }),
    },
    mounted() {
        this.context = 'serial-numbers'
    },
    methods: {
        addRow() {
            const newRow = {
                serial_number: null,
                observations: null,
                master_installation_id: this.master_installation.id,
            }
            this.serial_numbers.unshift(newRow)
            this.serial_numbers.editedRow = newRow
        },
        getButtonVariant(item) {
            return this.isRowEditable(item) ? 'text-green' : 'info'
        },
        getButtonIcon(item) {
            return this.isRowEditable(item) ? 'CheckIcon' : 'EditIcon'
        },
        getButtonText(item) {
            return this.isRowEditable(item) ? 'Guardar' : 'Editar'
        },
        isRowEditable(item) {
            return item === this.editedRow || item === this.serial_numbers.editedRow
        },
        editRow(item) {
            if (this.isRowEditable(item)) {
                this.editedRow = null
                this.updateRecord(item)

            } else {
                this.editedRow = item
            }
        },
        updateRecord(item) {

            item.context = this.context

            this.$store.dispatch('masterInstallationVhe/updateMasterInstallationSerialNumbers', this.serial_numbers)
            .then(() => {
                this.showLoading = false
                this.$root.$refs.masterInstallationForm.getMasterInstallation()
                helper.resetVariable(this.record)
                helper.showToast('Registro actualizado correctamente!', 'CheckIcon', 'success')
            })
            .catch(error => {
                this.showLoading = false
                helper.showToast('Error en editar al actualizar registro', 'AlertOctagonIcon', 'danger')
            })
        },
        deleteRecord(id, index) {
            if (id) {

                Swal.fire({
                    title: "Estás seguro que quieres borrar el registro?",
                    text: "Una vez borrado no se podrá recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "¡Sí, borralo!",
                    cancelButtonText: "Cancelar",
                }).then(result => {
                    if (result.value) {
                        this.showLoading = true
                        this.$store.dispatch('masterInstallationVhe/deleteMasterInstallationSerialNumber', {
                            serial_number: this.serial_numbers[index]
                        })
                        .then(response => {
                            this.showLoading = false
                            helper.showToast('Registro eliminado correctamente!', 'CheckIcon', 'success')
                            this.$root.$refs.masterInstallationForm.getMasterInstallation()
                        })
                        .catch(error => {
                            this.showLoading = false
                            console.log(error)
                        })
                    }
                })
            } else {
                this.serial_numbers.splice(index, 1)
            }
        },
    }

}
</script>
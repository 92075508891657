<template>
    <div>
        <form-manual-project></form-manual-project>
    </div>
</template>

<script>
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import formManualProject from "@/views/vhe/masters_installations/form/tabManualProject/components/formManualProject"

const {mapFields} = createHelpers({
    getterType: 'masterInstallationDocumentsVhe/getField',
    mutationType: 'masterInstallationDocumentsVhe/updateField',
})

export default {
    name: "FormGeneralManualProject",
    components: {
      formManualProject
    },
    data() {
        return {
            SpanishLocale,
            edit: false,
            showLoading: false,
        }
    },
    computed: {
        ...mapState('masterInstallationDocumentsVhe', []),
    },
    created() {

    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<template>
    <div>
        <b-card title="" border-variant="primary">
            <div class="d-flex align-items-center justify-content-between mb-1">

            </div>
            <form-communication-config/>
            <form-add-maintenance/>

            <form-base-document
                :documents-state-data="documents_maintenance"
                :documents-columns="columns"
                :documents-route="documents_by_route_maintenance"
                :context="'document-maintenance'"
                :create-permission="can('create_vhe_master_maintenance')"
                :delete-permission="can('delete_vhe_master_maintenance')"
            />
        </b-card>

    </div>
</template>

<script>
import {BButton, BCard} from 'bootstrap-vue'
import {mapState} from "vuex"
import formBaseDocument from "@/views/vhe/masters_installations/form/tabMaintenance/components/base/formBaseDocument"
import formCommunicationConfig
    from "@/views/vhe/masters_installations/form/tabMaintenance/components/forms/formCommunicationConfig"
import formAddMaintenance
    from "@/views/vhe/masters_installations/form/tabMaintenance/components/forms/formAddMaintenance"

import {mapFields} from "vuex-map-fields"

export default {
    name: "FormContractProviderDocuments",
    components: {
        BCard,
        formBaseDocument,
        formCommunicationConfig,
        formAddMaintenance
    },
    data() {
        return {
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'select_maintenance', label: 'Seleccionar', sortable: false},
                {key: 'periodicity', label: 'Periodicidad', sortable: false},
                {key: 'provider_id', label: 'Proveedor', sortable: false},
                {key: 'document_type_id', label: 'Tarea', sortable: false },
                {key: 'expected_date', label: 'Fecha Limite', sortable: false,  thStyle: { width: "20%" } },
                {key: 'effective_date', label: 'Fecha Realización', sortable: false},
                {key: 'operator_nif_1', label: 'NIF Operario 1', sortable: false},
                {key: 'operator_nif_2', label: 'NIF Operario 2', sortable: false},
                {key: 'report_review', label: 'Revisado Informe', sortable: false},
                {key: 'report_review_date', label: 'Fecha Revisión Informe', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable:false},
            ],
        }
    },
    computed: {
        ...mapState('masterInstallationVhe', ['master_installation']),
        ...mapState('masterInstallationDocumentsVhe', []),
        ...mapFields({
            documents_maintenance: 'masterInstallationDocumentsVhe.documents_maintenance',
            documents_by_route_maintenance: 'masterInstallationDocumentsVhe.documents_by_route_maintenance',
        }),
    },
    mounted() {
       this.context = 'document-maintenance'
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        addRow() {
            const newRow = {
                effective_date: null,
                acae_type_id: null,
                provider_id: null,
                route: this.master_installation.installation_code + "/MANTENIMIENTOS",
                observations: null,
                options: null,
                master_installation_id: this.master_installation.id,
            }

            this.documents_maintenance.unshift(newRow)
            this.documents_maintenance.editedRow = newRow
        },
    }

}
</script>
<template>
    <div>
        <b-form-group label="Código VHE" label-for="vhe-code">
            <b-form-input v-model="localInstallationCode" placeholder="VHE" autocomplete="off"
                          :class="validation ? 'is-invalid': ''"/>
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormInput} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormInput,
    },
    props: {
        installationCode: {
            type: String,
            default: ''
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        localInstallationCode: {
            get() {
                return this.installationCode
            },
            set(value) {
                this.$emit('update', {installation_code: value})
            },
        },
    },
}
</script>

<template>
  <div>
    <b-form-group label="Territorios OPSVA" label-for="territory_opsva_id">
      <v-select
          v-model="territoryOpsvaId"
          class="pt-0" label="name" name="territory_opsva_id"
          :reduce="(option) => option.id" :disabled="disabled"
          :options="territories"
      />
      <small v-if="validation" class="text-danger">
        {{ validation }}</small>
    </b-form-group>
  </div>
</template>

<script>
import {BFormGroup, BFormInput} from 'bootstrap-vue'
import vSelect from "vue-select"

export default {
  components: {
    vSelect,
    BFormGroup,
  },
  props: {
    territoryOpsvaId: {
      type: Number,
      default: null
    },
    validation: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localTerritoryOpsvaId: this.territoryOpsvaId,
      territories: [
        {'id': 1, 'name': 'EXPANSIÓN'},
        {'id': 2, 'name': 'ANDALUCÍA/CANARIAS'},
        {'id': 3, 'name': 'CATALUÑA/ARAGÓN/BALEARES'},
        {'id': 4, 'name': 'INTERNACIONAL'}
      ]
    }
  },
  watch: {
    territoryOpsvaId(newVal) {
      this.localTerritoryOpsvaId = newVal
      this.$emit('update', {territory_opsva_id: this.localTerritoryOpsvaId})
    },
  },

}
</script>

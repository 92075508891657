<template>
    <div>
        <b-form-group :label="customLabel" label-for="level_3_id">
            <v-select
                v-model="level3id"
                class="pt-0" label="name" name="name"
                :reduce="(option) => option.id"
                :options="levels3List"
                :disabled="disabled"
            />
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
import vSelect from "vue-select"

export default {
    components: {
        BFormGroup,
        vSelect,
    },
    props: {
        //eslint-disable-next-line vue/prop-name-casing
        level3id: {
            type: Number,
            default: null
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        customLabel: {
            type: String,
            default: "Nível 3"
        }
    },
    data() {
        return {
            levels3List: [],
            localLevel3Id: this.level3id,
        }
    },
    watch: {
        level3id(newVal) {
            this.localLevel3Id = newVal
            this.$emit('update', { level_3_id: this.localLevel3Id })
        },
    },
    mounted() {
        this.getLevels3()
    },
    methods:{
        getLevels3() {
            this.showLoading = true

            this.$store.dispatch('level3Vhe/getAllLevels3')
            .then(response => {
                this.levels3List = response

                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
    },
}
</script>

<template>
    <div>
        <form-transfer-certificate :disabled="false"/>
        <form-contract-client-e-e :disabled="false"/>
        <form-specific-plan-prevention/>
        <form-contract-provider-e-e/>
    </div>
</template>

<script>
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'

import formContractClientEE
    from "@/views/vhe/masters_installations/form/tabDocuments/components/formContractClientEE"
import formTransferCertificate
    from "@/views/vhe/masters_installations/form/tabDocuments/components/formTransferCertificate"
import formContractProviderEE
    from "@/views/vhe/masters_installations/form/tabDocuments/components/formContractProviderEE"
import formSpecificPlanPrevention
    from "@/views/vhe/masters_installations/form/tabDocuments/components/formSpecificPlanPrevention"

const {mapFields} = createHelpers({
    getterType: 'masterInstallationDocumentsVhe/getField',
    mutationType: 'masterInstallationDocumentsVhe/updateField',
})

export default {
    name: "FormGeneralDataAndRisksView",
    components: {
        formContractClientEE,
        formTransferCertificate,
        formContractProviderEE,
        formSpecificPlanPrevention

    },
    data() {
        return {
            SpanishLocale,
            edit: false,
            showLoading: false,
        }
    },
    computed: {
        ...mapState('masterInstallationDocumentsVhe', []),
        ...mapFields({

        }),
    },
    created() {

    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
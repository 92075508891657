<template>
  <div>
    <b-form-group label="Público Infra" label-for="public_infra">
      <v-select v-model="publicInfra" class="pt-0" label="name" name="public_infra" :reduce="(option) => option.id" :disabled="disabled" :options="publicInfraOptions"/>
      <small v-if="validation" class="text-danger">{{validation}}</small>
    </b-form-group>
  </div>
</template>

<script>
  import {BFormGroup, BFormInput} from 'bootstrap-vue'
  import vSelect from "vue-select"

  export default {
      components: {
        vSelect,
        BFormGroup,
      },
      props: {
        publicInfra: {
          type: String,
          default: null
      },
      validation: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        localPublicInfra: this.publicInfra,
        publicInfraOptions: [
          {'id': 'NNSS', 'name': 'NNSS'},
          {'id': 'CPOaaS', 'name': 'CPOaaS'},
          {'id': 'CPOaaS just O&M', 'name': 'CPOaaS just O&M'}
        ]
      }
    },
    watch: {
      publicInfra(newVal) {
        this.localPublicInfra = newVal
        this.$emit('update', {public_infra: this.localPublicInfra})
      },
    },
  }
</script>

<template>
    <div>
        <b-table
            :items="documentsStateData"
            :fields="documentsColumns"
            responsive
            striped
            primary-key="id"
            sticky-header
            show-empty
            editable
            class="table-container"
            empty-text="No hay registros"
        >
            <template #cell(color)="{ item }">

                <div
                    :style="cellStyle(item)">
                    <template/>
                </div>
            </template>

            <template #cell(expected_date)="{ item }">
                <template v-if="isRowEditable(item)">
                    <flat-pickr
                        v-model="item.expected_date"
                        class="form-control width-115"
                        autocomplete="off"
                        name="effective_date"
                        :config="{ dateFormat: 'd-m-Y', locale: SpanishLocale, allowInput: true, altInput: true, altFormat: 'd-m-Y' }"
                    />
                </template>
                <template v-else>
                    <span class="text-nowrap">
                        {{ item.expected_date }}
                    </span>
                </template>
            </template>

            <template #cell(passed)="{ item }">
                <template v-if="isRowEditable(item)">
                    <b-form-group class="form-check form-check-inline">
                        <b-form-checkbox v-model="item.passed" :value="1"/>
                    </b-form-group>
                </template>
                <template v-else>
                    <b-form-checkbox v-model="item.passed" disabled="disabled" :value="1"/>
                </template>
            </template>

            <template #cell(defects)="{ item }">
                <template v-if="isRowEditable(item)">
                    <b-form-group class="form-check form-check-inline">
                        <b-form-checkbox v-model="item.defects" :value="1"/>
                    </b-form-group>
                </template>
                <template v-else>
                    <b-form-checkbox v-model="item.defects" disabled="disabled" :value="1"/>
                </template>
            </template>


            <template #cell(effective_date)="{ item }">
                <div :style="{ backgroundColor: item.has_documents ? 'rgba(0, 167, 225, 0.2)' : '' }">
                    <template v-if="isRowEditable(item)">
                        <flat-pickr
                            v-model="item.effective_date"
                            class="form-control width-115"
                            autocomplete="off"
                            name="effective_date"
                            :config="{ dateFormat: 'd-m-Y', locale: SpanishLocale, allowInput: true, altInput: true, altFormat: 'd-m-Y' }"
                        />
                    </template>
                    <template v-else>
                        <span class="text-nowrap">
                            {{ item.effective_date }}
                        </span>

                    </template>
                </div>
            </template>

            <template #cell(observations)="{ item }">
                <template v-if="isRowEditable(item)">
                    <b-form-textarea v-model="item.observations" autocomplete="off"/>
                </template>
                <template v-else>
                    {{ item.observations }}
                </template>
            </template>

          <template #cell(periodicity)="{ item }">
            <template v-if="isRowEditable(item)">
              <select class="custom-select custom-width-select" v-model="item.periodicity">
                <option v-for="option in ocasPeriodicity" :key="option.value" :value="option.value">{{ option.name }}</option>
              </select>
            </template>
            <template v-else>
              {{ item.periodicity }}
            </template>
          </template>



          <template #cell(acting)="{ item }">
                <template v-if="isRowEditable(item)">
                    <b-form-input v-model="item.acting" autocomplete="off"/>
                </template>
                <template v-else>
                    {{ item.acting }}
                </template>
            </template>

            <template #cell(performance)="{ item }">
                <template v-if="isRowEditable(item)">
                    <b-form-input v-model="item.performance" autocomplete="off"/>
                </template>
                <template v-else>
                    {{ item.performance }}
                </template>
            </template>


            <template #cell(periodicity_observations)="{ item }">

                <template v-if="isRowEditable(item)">
                    <b-form-textarea v-model="item.periodicity_observations" autocomplete="off"/>
                </template>
                <template v-else>
                    {{ item.periodicity_observations }}
                </template>
            </template>

<!--          <template #cell(inspection.type)="{ item }">-->
<!--            <template v-if="isRowEditable(item)">-->
<!--              <select class="custom-select custom-width-select" v-model="item.oca_inspection_id">-->
<!--                <option v-for="option in ocasType" :key="option.value" :value="option.value">{{ option.name }}</option>-->
<!--              </select>-->
<!--            </template>-->
<!--            <template v-else>-->
<!--              {{ item.inspection ? item.inspection.type : '' }}-->

<!--            </template>-->
<!--          </template>-->

            <template #cell(options)="data">
                <div class="d-flex align-items-center">
                    <feather-icon
                        v-if="createPermission"
                        :id="`record-row-${data.item.id}-edit-icon`"
                        :icon="getButtonIcon(data.item)"
                        class="cursor-pointer mr-1"
                        :class="getButtonVariant(data.item)"
                        size="16"
                        @click="editRow(data.item)"
                    />

                    <feather-icon
                        v-if="data.item.id"
                        :id="`record-row-${data.item.id}-see-icon`"
                        icon="SearchIcon"
                        size="16"
                        variant="warning"
                        class="cursor-pointer mr-1 text-blue"
                        @click="seeDocumentsList(data.item.id)"
                    />
                    <feather-icon
                        v-if="deletePermission"
                        :id="`record-row-${data.item.id}-delete-icon`"
                        icon="TrashIcon"
                        size="16"
                        class="cursor-pointer text-red"
                        @click="deleteRecord(data.item.id, data.index )"
                    />

                </div>
            </template>

        </b-table>

        <b-modal v-model="showModal" hide-footer title="Documentos" size="lg">
            <b-table
                ref="refUserListTable"
                :items="documentsRoute"
                responsive
                :fields="columnsDocuments"
                primary-key="id"
                show-empty
                sticky-header
                striped
                editable
                empty-text="No hay registros"
                class="table-bordered "
                :key-field="'index'"
            >
                <template #cell(options)="data">
                    <div class="d-flex align-items-center justify-content-center">
                        <a :href="baseUrl+data.item.path" target="_blank">
                            <feather-icon
                                v-if="!can('vhe_delete_document_contract_client_ee')"
                                :id="`record-row-${data.item.id}-see-icon`"
                                icon="EyeIcon"
                                size="16"
                                variant="warning"
                                class="cursor-pointer mr-1 text-blue"
                            />
                        </a>
                        <feather-icon
                            v-if="!can('vhe_delete_document_contract_client_ee')"
                            :id="`record-row-${data.item.id}-delete-icon`"
                            icon="TrashIcon"
                            size="16"
                            class="cursor-pointer text-red"
                            @click="deleteDocument(data.item.id, data.item.path)"
                        />
                    </div>
                </template>
            </b-table>
            <template>
                <div v-if="currentDocumentId>0">
                    <b-overlay
                        variant="white"
                        :show="showLoading"
                        spinner-variant="primary"
                        blur="0"
                        opacity=".75"
                        rounded="sm"
                    >
                        <vue-dropzone
                            id="dropzone"
                            ref="dropzone"
                            :options="dropzoneOptions"
                            :use-custom-slot="true"
                            @vdropzone-complete="complete">
                            <div class="dropzone-custom-content">
                                <h3 class="dropzone-custom-title">Arrastrar y soltar para subir archivo</h3>
                                <div class="subtitle">...o hacer clic para seleccionar un archivo de tu ordenador
                                </div>
                            </div>
                        </vue-dropzone>
                    </b-overlay>
                </div>
            </template>

            <b-button class="mt-3" variant="outline-danger" block @click="closeModal()">Cerrar</b-button>
        </b-modal>
    </div>
</template>

<script>
import { BTable, BFormInput, BModal, BButton, BOverlay, BFormGroup, BFormCheckbox, BFormTextarea } from 'bootstrap-vue'
import {baseUrl} from "@/constants/app"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import authService from "@/services/auth/authService"
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import flatPickr from "vue-flatpickr-component"
import VueDropzone from "vue2-dropzone"
import helper from "@/resources/helpers/helpers"
import Swal from "sweetalert2"
import {createPopper} from '@popperjs/core'
import {ocasPeriodicity, ocasType} from "@/constants/constants"

export default {
    components: {
        BTable,
        BFormInput,
        BModal,
        BButton,
        BOverlay,
        BFormCheckbox,
        flatPickr,
        BFormTextarea,
        BFormGroup,
        VueDropzone: VueDropzone,
    },
    props: {
        documentsStateData: {
            type: Array,
            required: true
        },
        documentsColumns: {
            type: Array,
            required: true
        },
        documentsRoute: {
            type: Array,
            required: true
        },
        context: {
            type: String,
            required: true
        },
        createPermission: {
            type: Boolean,
        },
        deletePermission: {
            type: Boolean,
        }
    },

    data() {
        return {
            baseUrl,
            currentDocumentId: null,
            selectedRow: null,
            placement: 'top',

            showModal: false,
            SpanishLocale,
            edit: false,
            showLoading: false,
            urlDocs: null,
            editedRow: null,
            columnsDocuments: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'name', label: 'Archivo', sortable: false},
            ],
            dropzoneOptions: {},

          ocasType,
          ocasPeriodicity
        }
    },
    computed: {
        cellStyle() {
            return function (item) {
                const baseStyle = {
                    width: '50px',
                    height: '50px'
                }
                const today = new Date()

                let expectedDate = item.expected_date
                if (expectedDate) {
                    var dateParts = expectedDate.split('-')
                    if (dateParts.length === 3) {
                        var expectedDateFormatted = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0]
                    }

                    if (item.effective_date !== null) {
                        return {...baseStyle, backgroundColor: 'rgba(52,159,29,0.2)'}
                    } else if (item.expected_date !== null && item.effective_date !== undefined && new Date(expectedDateFormatted) > today) {
                        return {...baseStyle, backgroundColor: 'rgba(9,190,255,0.2)'}
                    } else if (item.expected_date !== null && (item.effective_date == undefined || item.effective_date == null) && new Date(expectedDateFormatted) < today) {
                        return {...baseStyle, backgroundColor: 'rgba(255,9,95,0.2)'}
                    } else {
                        return {...baseStyle}
                    }
                }
                return {...baseStyle}
            }
        }

    },
    mounted() {
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        withPopper(dropdownList, component, {width}) {
            dropdownList.style.width = width
            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: this.placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1],
                        },
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({state}) {
                            component.$el.classList.toggle(
                                'drop-up',
                                state.placement === 'top'
                            )
                        },
                    },
                ],
            })


            return () => popper.destroy()
        },
        isRowEditable(item) {
            return item === this.editedRow || item === this.documentsStateData.editedRow
        },
        editRow(item) {
            if (this.isRowEditable(item)) {
                this.editedRow = null
                this.updateRecord(item)

            } else {
                this.editedRow = item
            }
        },
        getButtonVariant(item) {
            return this.isRowEditable(item) ? 'text-green' : 'info'
        },
        getButtonIcon(item) {
            return this.isRowEditable(item) ? 'CheckIcon' : 'EditIcon'
        },
        getButtonText(item) {
            return this.isRowEditable(item) ? 'Guardar' : 'Editar'
        },
        closeModal() {
            this.showModal = false
            this.$root.$refs.masterInstallationForm.getMasterInstallation()
        },
        seeDocumentsList(id) {
            this.currentDocumentId = id
            this.updateDropzoneOptions()
            this.$store.dispatch('masterInstallationDocumentsVhe/getDocumentsList', {id: id, context: this.context})
            .then(response => {
                this.showLoading = false
                this.showModal = true
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        updateDropzoneOptions() {
            this.dropzoneOptions = {
                url: baseUrl + 'api/vhe/master-installation/general-data/' + this.context + '/upload-documents/' + this.currentDocumentId,
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                autoProcessQueue: true,
                addRemoveLinks: true,
                headers: {"Authorization": 'Bearer ' + authService.accessToken()},
            }
        },
        complete: function (file) {
            this.showLoading = true
            this.seeDocumentsList(this.currentDocumentId)
            setTimeout(() => {
                this.$refs.dropzone.removeAllFiles()
            }, 2000)
        },
        updateRecord(item) {
            item.context = this.context

            if (item.id) {
                this.$store.dispatch('masterInstallationDocumentsVhe/updateRecordGeneralData', item)
                .then(() => {
                    this.showLoading = false
                    this.$root.$refs.masterInstallationForm.getMasterInstallation()
                    helper.resetVariable(this.record)
                    helper.showToast('Registro actualizado correctamente!', 'CheckIcon', 'success')
                })
                .catch(error => {
                    this.showLoading = false
                    helper.showToast('Error en editar al actualizar registro', 'AlertOctagonIcon', 'danger')
                })
            } else {
                this.$store.dispatch('masterInstallationDocumentsVhe/createRecordGeneralData', item)
                .then(() => {
                    this.showLoading = false
                    this.$root.$refs.masterInstallationForm.getMasterInstallation()
                    helper.resetVariable(this.record)
                    helper.showToast('Registro creado correctamente!', 'CheckIcon', 'success')
                })
                .catch(error => {
                    this.showLoading = false
                    helper.showToast('Error en editar al crear registro', 'AlertOctagonIcon', 'danger')
                })
            }
        },
        deleteRecord(id, index) {
            if (id) {
                Swal.fire({
                    title: "Estás seguro que quieres borrar el registro?",
                    text: "Una vez borrado no se podrá recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "¡Sí, borralo!",
                    cancelButtonText: "Cancelar",
                }).then(result => {
                    if (result.value) {
                        this.showLoading = true
                        this.$store.dispatch('masterInstallationDocumentsVhe/deleteRecord', {
                            id: id,
                            context: this.context
                        })
                        .then(response => {
                            this.showLoading = false
                            helper.showToast('Registro eliminado correctamente!', 'CheckIcon', 'success')
                          this.$root.$refs.masterInstallationForm.getMasterInstallation()
                        })
                        .catch(error => {
                            this.showLoading = false
                            console.log(error)
                        })
                    }
                })
            } else {
                this.documentsStateData.splice(index, 1)
            }
        },
        deleteDocument(recordId, path) {
            if (recordId) {
                Swal.fire({
                    title: "Estás seguro que quieres borrar el archivo?",
                    text: "Una vez borrado no se podrá recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "¡Sí, borralo!",
                    cancelButtonText: "Cancelar",
                }).then(result => {
                    if (result.value) {
                        this.showLoading = true
                        this.$store.dispatch('masterInstallationDocumentsVhe/deleteDocument', {
                            id:  this.currentDocumentId,
                            path: path,
                            context: this.context
                        })
                        .then(response => {
                            this.showLoading = false
                            this.$root.$refs.masterInstallationForm.getMasterInstallation()
                            helper.showToast('Registro eliminado correctamente!', 'CheckIcon', 'success')
                            this.seeDocumentsList(this.currentDocumentId)
                        })
                        .catch(error => {
                            this.showLoading = false
                            console.log(error)
                        })
                    }
                })
            }
        },


    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
<template>
    <div>
        <b-card border-variant="primary" class="p-0">
            <h5 class="mb-0">Agregar mantenimientos automaticamente</h5>
            <b-row sm="12">
                <b-col sm="2">

                    <b-form-group label-for="period" class="mt-2">
                        <label>Periodo</label>
                        <v-select
                            v-model="maintenance_config.periodicity"
                            class="pt-0" label="name" name="name"
                            :reduce="(option) => option.value"
                            :options="filteredMaintenancePeriods"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    <b-form-group label-for="task_type_id" class="mt-2">
                        <label>Tarea</label>
                        <v-select
                            v-model="maintenance_config.document_type_id"
                            class="pt-0" label="type" name="type"
                            :reduce="(option) => option.id"
                            :options="filteredMaintenanceTasksTypes"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    <b-form-group label-for="provider_id" class="mt-2">
                        <label>Proveedor</label>
                        <v-select
                            v-model="maintenance_config.provider_id"
                            class="pt-0" label="provider" name="provider"
                            :reduce="(option) => option.id"
                            :options="providers"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="2">
                    <b-form-group label-for="start_date" class="mt-2">
                        <label>Fecha Inicial</label>
                        <flat-pickr
                            v-model="maintenance_config.start_date"
                            class="form-control"
                            name="start_date"
                            autocomplete="off"
                            :config="{ dateFormat: 'd-m-Y', locale: SpanishLocale, allowInput: true, altInput: true, altFormat: 'd-m-Y'}"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="2">
                    <b-form-group label-for="end_date" class="mt-2">
                        <label>Fecha Final</label>
                        <flat-pickr
                            v-model="maintenance_config.end_date"
                            class="form-control"
                            name="end_date"
                            autocomplete="off"
                            :config="{ dateFormat: 'd-m-Y', locale: SpanishLocale, allowInput: true, altInput: true, altFormat: 'd-m-Y' }"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="2" class="mt-2">
                    <b-form-group label-for="button" class="mt-2">
                        <b-button v-if="can('create_vhe_master')" variant="outline-info" block @click="generateMaintenance()">Generar</b-button>
                    </b-form-group>
                </b-col>

            </b-row>
        </b-card>
    </div>
</template>

<script>
import {BFormGroup, BCard, BCol, BRow, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import {mapFields} from "vuex-map-fields"
import vSelect from "vue-select"
import {maintenancePeriods} from "@/constants/childrenDocuments"
import flatPickr from "vue-flatpickr-component"

export default {
    name: "FormView",
    components: {
        BCol,
        BCard,
        BRow,
        BFormGroup,
        BButton,

        flatPickr,
        vSelect
    },

    data() {
        return {
            SpanishLocale,
            edit: false,
            showLoading: false,
            isDisabled: true,
            maintenance_config: {},

            maintenancePeriods
        }
    },
    computed: {
        ...mapState('masterInstallationVhe', ['master_installation']),
        ...mapState('masterInstallationDocumentsVhe', []),
        ...mapState('maintenanceTaskTypesVhe', []),
        ...mapFields({
            providers: 'providersVhe.providers',
            maintenance_task_types: 'maintenanceTaskTypesVhe.maintenance_task_types',
            documentsTypes: 'documentsTypesVhe.document_types',
        }),
        filteredMaintenancePeriods() {
            return maintenancePeriods.filter(option => option.value !== "initial")
        },
        filteredMaintenanceTasksTypes() {
            return this.documentsTypes.filter(option => option.to_maintenance == 1)
        },
    },
    methods:{
        can(permission) {
            return this.$store.getters.can(permission)
        },
        generateMaintenance(){
            this.maintenance_config.master_installation_id = this.master_installation.id
            this.maintenance_config.route = this.master_installation.installation_code + "/MANTENIMIENTOS"
            this.$root.$refs.tabMaintenanceForm.updateRecord(this.maintenance_config)
        },
    }

}
</script>
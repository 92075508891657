<template>
    <div>
        <b-card :border-variant="!disabled ? 'primary':'secondary'">
            <div class="d-flex align-items-center justify-content-between mb-1">
                <h5 class="mb-0">Acta de Traspaso</h5>
                <b-button v-if="!disabled"
                    variant="outline-success"
                    @click="addRow"
                >
                    <feather-icon
                        icon="PlusIcon"
                        size="16"
                        variant="success"
                        class="cursor-pointer "

                    />
                </b-button>

            </div>
            <form-base-document
                :documents-state-data="documents_transfer_certificate"
                :documents-columns="columns"
                :documents-route="documents_by_route_transfer_certificate"
                :context="'transfer-certificate'"
                :is-disabled="disabled"
                :create-permission="can('create_vhe_master_transfer_certificate')"
                :delete-permission="can('delete_vhe_master_transfer_certificate')"
            />

        </b-card>
    </div>
</template>

<script>
import {BCard, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import formBaseDocument
    from "@/views/vhe/masters_installations/form/tabDocuments/components/base/formBaseDocument"

const {mapFields} = createHelpers({
    getterType: 'masterInstallationDocumentsVhe/getField',
    mutationType: 'masterInstallationDocumentsVhe/updateField',
})

export default {
    name: "FormGeneralDocuments",
    components: {
        BButton,
        BCard,
        formBaseDocument
    },
    props: {
        disabled: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'transfer_date', label: 'Fecha Traspaso', sortable: false},
                {key: 'provider_id', label: 'Proveedor', sortable: false},
                {key: 'project_responsible_id', label: 'Gestor de Proyectos', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
            ],

        }
    },
    computed: {
        ...mapState('masterInstallationVhe', ['master_installation']),
        ...mapState('masterInstallationDocumentsVhe', []),
        ...mapFields({
            documents_transfer_certificate: 'documents_transfer_certificate',
            documents_by_route_transfer_certificate: 'documents_by_route_transfer_certificate',
        }),
    },
    mounted() {
        this.context = 'transfer-certificate'
        this.getAllProviders()
     //   this.getAllOrderMaintenanceTypeId()
     //   this.getAllSubOrderMaintenanceTypeId()
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        addRow() {
            const newRow = {
                transfer_date: null,
                provider_id: null,
                project_responsible_id: null,
                route: this.master_installation.installation_code + '/ACTA_TRASPASO',
                observations: null,
                options: null,
                master_installation_id: this.master_installation.id,
            }

            this.documents_transfer_certificate.push(newRow)
            this.documents_transfer_certificate.editedRow = newRow
        },
        getAllProviders() {
            this.showLoading = true

            this.$store.dispatch('providersVhe/getAllProviders')
            .then(response => {
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        getAllOrderMaintenanceTypeId() {
          this.showLoading = true

          this.$store.dispatch('orderMaintences/getAllOrderMaintenanceTypeId')
              .then(response => {
                this.showLoading = false
              })
              .catch(error => {
                this.showLoading = false
              })
        },
      getAllSubOrderMaintenanceTypeId() {
          this.showLoading = true

          this.$store.dispatch('orderSubMaintences/getAllSubOrderMaintenanceTypeId')
              .then(response => {
                this.showLoading = false
              })
              .catch(error => {
                this.showLoading = false
              })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir] .modal .modal-header .close {
    box-shadow: none;
    transform: unset;
    background: none;
}
.table-container {
    max-height: 400px; /* Ajuste a altura máxima desejada */
    overflow-y: auto;
}
</style>
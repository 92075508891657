<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <form-header/>
                    <div v-if="master_installation.id">
                        <hr>
                        <b-tabs>
                            <b-tab active title="Datos Instalación">
                                <form-installation-data/>
                            </b-tab>
                            <b-tab title="ACAE/Concurrencia">
                                <main-form-document-a-c-a-e/>
                            </b-tab>
                            <b-tab title="Documentación">
                                <main-form-documents/>
                            </b-tab>
                            <b-tab title="Instalaciones">
                                <list-children-installations/>
                            </b-tab>
                            <b-tab title="OCA">
                                <main-form-oca/>
                            </b-tab>
                            <b-tab title="Doc. Proy. y Man.">
                                <main-form-manual-project/>
                            </b-tab>
                            <b-tab title="Mantenimiento">
                                <main-form-document-maintenance/>
                            </b-tab>
                        </b-tabs>
                    </div>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {BTab, BCard, BCol, BTabs, BOverlay, BRow} from 'bootstrap-vue'
import {mapState} from "vuex"
import {VHE_MASTERS_INSTALLATIONS_LIST} from "@/router/web/constants"
import {createHelpers} from "vuex-map-fields"
import FormInstallationData from "@/views/vhe/masters_installations/form/formInstallationData/formInstallationData"
import ListChildrenInstallations from "@/views/vhe/masters_installations/form/components/listChildrenInstallations"
import FormHeader from "@/views/vhe/masters_installations/form/formHeader/formHeader"
import helper from "@/resources/helpers/helpers"
import mainFormDocumentACAE from "@/views/vhe/masters_installations/form/tabDocumentACAE/mainFormDocumentACAE.vue"
import mainFormDocuments from "@/views/vhe/masters_installations/form/tabDocuments/mainFormDocuments.vue"
import mainFormOca from "@/views/vhe/masters_installations/form/tabOca/mainFormOca.vue"
import mainFormManualProject from "@/views/vhe/masters_installations/form/tabManualProject/mainFormManualProject.vue"
import mainFormDocumentMaintenance
    from "@/views/vhe/masters_installations/form/tabMaintenance/mainFormDocumentMaintenance.vue"

const {mapFields} = createHelpers({
    getterType: 'masterInstallationVhe/getField',
    mutationType: 'masterInstallationVhe/updateField',
})

export default {
    name: "FormView",
    components: {
        mainFormDocumentMaintenance,
        mainFormManualProject,
        FormHeader,
        BCol,
        BRow,
        BCard,
        BOverlay,
        BTab,
        BTabs,

        mainFormOca,
        mainFormDocumentACAE,
        mainFormDocuments,
        FormInstallationData,
        ListChildrenInstallations
    },
    data() {
        return {
            edit: false,
            showLoading: false,
        }
    },
    computed: {
        ...mapState('masterInstallationVhe', ['validation_master_installation']),
        ...mapFields({
            master_installation: 'master_installation',
        }),
    },
    created() {
        this.$store.commit('masterInstallationVhe/RESET_STATE')
        this.$store.commit('masterInstallationVhe/CLEAR_VALIDATION')
        if (this.$route.params.id) {
            this.edit = true
            this.getMasterInstallation()
        } else {
            this.edit = false
        }
        this.$root.$refs.masterInstallationForm = this
    },
    methods: {
        getMasterInstallation() {
            this.showLoading = true
            this.$store.dispatch('masterInstallationVhe/getMasterInstallation', {
                relations: ['documentACAE', 'responsible', 'clientContractEE', 'segment', 'installationType','documentOca',
                    'specificPrevention','providerContractEE', 'transferCertificate', 'childrenInstallation',
                    'childrenInstallation.installationType', 'documentOca.inspection', 'documentManualProject',
                    'documentMaintenance', 'documentMaintenance.inspection', 'documentMaintenance.atex',
                    'documentMaintenance.invigilando', 'documentMaintenance.insitu', 'serialNumbers'],
                id: this.$route.params.id ?? this.master_installation.id,
            })
                .then(response => {

                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        cancel() {
            this.$router.push(VHE_MASTERS_INSTALLATIONS_LIST)
        },
        createInstallation() {
            this.$store.dispatch('masterInstallationVhe/createMasterInstallation', this.master_installation)
                .then(response => {
                    //   this.master_installation.id = response.id
                    helper.showToast('Instalación padre creada correctamente!', 'CheckIcon', 'success')
                    this.$router.replace(VHE_MASTERS_INSTALLATIONS_LIST)

                })
                .catch(error => {
                    helper.showToast('Error al crear instalación', 'AlertOctagonIcon', 'danger')
                    console.log(error)
                })
        },
        updateInstallation() {
            this.$store.dispatch('masterInstallationVhe/updateMasterInstallation', this.master_installation)
                .then(response => {
                    helper.showToast('Instalación padre editada correctamente!', 'CheckIcon', 'success')
                })
                .catch(error => {
                    helper.showToast('Error al editar instalación', 'AlertOctagonIcon', 'danger')
                    console.log(error)
                })
        },

    },
}
</script>

<template>
    <div>
        <b-col sm="3">
            <b-form-group label="Código PRVE">
                <b-form-input v-model="localInstallationTypeCode" autocomplete="off" disabled="disabled"
                />
            </b-form-group>
        </b-col>
        <b-col sm="6">
            <b-form-group label="Código PRVE">
                <b-form-input v-model="localInstallationCode" autocomplete="off"/>
            </b-form-group>
        </b-col>
        <b-col xl="12">
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-col>
    </div>
</template>

<script>
import {BFormGroup, BFormInput, BCol, BRow} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormInput,
        BCol,
    },
    props: {
        installationTypeCode: {
            type: String,
            default: ''
        },
        installationCode: {
            type: String,
            default: ''
        },
        installationId: {
            default: '---'
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        localInstallationTypeCode: {
            get() {
                return this.installationTypeCode
            },
            set(value) {
                this.$emit('update', {installation_type_code: value})
            },
        },
        localInstallationCode: {
            get() {
                return this.installationCode
            },
            set(value) {
                this.$emit('update', {installation_code: value})
                this.$emit('update', {vhe_code: value})
            },
        },
    },
}
</script>
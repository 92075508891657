<template>
    <div>
        <b-form-group label="OR" label-for="or">
            <b-form-input v-model="or" placeholder="OR" :disabled="disabled"
                          :class="validation ? 'is-invalid': ''"/>
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormInput} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormInput,
    },
    props: {
        or: {
            type: String,
            default: ''
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localOr: this.or,
        }
    },
    watch: {
        or(newVal) {
            this.localOr = newVal
            this.$emit('update', { or_number: this.localOr })
        },
    },
}
</script>

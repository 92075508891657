<template>
    <div>
        <b-form-group label-for="dias" class="mt-2">
            <b-form-group class="form-check form-check-inline">
                <b-form-checkbox v-model="include_secure_list" :value="1" :disabled="disabled">
                   Incluir en listado seguro
                </b-form-checkbox>
            </b-form-group>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormCheckbox} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormCheckbox,
    },
    props: {
        include_secure_list: {
            type: Number,
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localIncludeSecureList: this.include_secure_list,
        }
    },
    watch: {
        active(newVal) {
            this.localIncludeSecureList = newVal
            this.$emit('update', { include_secure_list: this.localIncludeSecureList })
        },
    },
}
</script>

<template>
    <div>
        <b-form-group label="Tipo Instalación" label-for="installation_type">
            <v-select
                v-model="localInstallationTypeId"
                class="pt-0" label="type" name="type"
                :reduce="(option) => option.id"
                :options="installation_type"
                :disabled="disabled"
                @input="handleInstallationTypesChange"
            />

            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
import vSelect from "vue-select"
import {mapState} from "vuex"
import {mapFields} from "vuex-map-fields"
import helpers from "@/resources/helpers/helpers"

export default {
    components: {
        BFormGroup,
        vSelect,
    },
    props: {
        installationTypeId: {
            type: Number,
            default: null
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            installation_type: [],
        }
    },
    computed: {
        ...mapState('installationTypesVhe', []),
        ...mapFields({
            installation_types: 'installationTypesVhe.installation_types',
        }),
        localInstallationTypeId: {
            get() {
                return this.installationTypeId
            },
            set(value) {
                this.handleInstallationTypesChange(value)
                this.$emit('update', { installation_type_id: value})
            },
        },
    },
    mounted() {
        this.getInstallationTypes()
    },
    methods:{
        getInstallationTypes() {
            this.showLoading = true

            this.$store.dispatch('installationTypesVhe/getAllInstallationTypes')
                .then(response => {
                    this.installation_type = response
                    //TODO: cambiar para el state de instalacion

                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        handleInstallationTypesChange(selectedType) {
            const installationType = this.installation_type.find(seg => seg.id === selectedType)
            if (installationType) {
                this.installation_type_code = installationType.code
            }
            this.$emit('update', { installation_type_code: this.installation_type_code })
        },
    },

}
</script>
<template>
    <div>
        <div v-if="master_installation.id">
            <b-row sm="12">
                <b-col sm="6">
                    <installation-code-input :installation-code="master_installation.installation_code"
                                             :validation="validation_master_installation.installation_code"
                                             @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="6">
                    <client-input :client="master_installation.client"
                                  :validation="validation_master_installation.client"
                                  @update="handleUpdateFields"></client-input>
                </b-col>
            </b-row>
        </div>
        <div v-if="!master_installation.id">
            <b-row sm="12">
                <b-col sm="1">
                    &nbsp;
                    <b-form-group>
                        <b-form-input v-model="master_installation.installation_type_code" autocomplete="off" disabled="disabled"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    Código PRVE
                    <b-form-group>
                        <b-form-input v-model="master_installation.vhe_code" autocomplete="off"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    <date-start-flat-pickr :date-start="master_installation.start_date"
                                           :validation="validation_master_installation.start_date"
                                           @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="3">
                    <date-end-flat-pickr :date-end="master_installation.end_date"
                                         :validation="validation_master_installation.end_date"
                                         @update="handleUpdateFields"/>
                </b-col>
                <b-col class="mr-1" style="margin-top: 20px">
                    <b-button v-if="!edit" variant="outline-success" block @click="createInstallation()">
                        Crear
                    </b-button>
                    <b-button v-if="edit" variant="outline-success" block @click="updateInstallation()">
                        Guardar
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import {BCol, BRow, BButton, BFormInput, BFormGroup} from 'bootstrap-vue'
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import installationCodeInput from "@/views/vhe/masters_installations/form/formHeader/components/installationCodeInput"
import installationCodeConcatInput from "@/views/vhe/masters_installations/form/formHeader/components/installationCodeConcatInput"
import clientInput from "@/views/vhe/masters_installations/form/formInstallationData/components/clientInput"
import DateStartFlatPickr
    from "@/views/vhe/masters_installations/form/formInstallationData/components/dateStartFlatPickr.vue"
import DateEndFlatPickr
    from "@/views/vhe/masters_installations/form/formInstallationData/components/dateEndFlatPickr.vue"

import {VHE_MASTERS_INSTALLATIONS_LIST} from "@/router/web/constants"

const {mapFields} = createHelpers({
    getterType: 'masterInstallationVhe/getField',
    mutationType: 'masterInstallationVhe/updateField',
})

export default {
    components: {
        DateEndFlatPickr,
        DateStartFlatPickr,
        clientInput,
        installationCodeInput,
        //installationCodeConcatInput,
        // installationTypeIdSelect,
        // segmentIdSelect,

        BCol,
        BRow,
        BButton,
        BFormInput,
        BFormGroup
    },
    data() {
        return {
            edit: false,
            showLoading: false,
        }
    },
    computed: {
        ...mapState('masterInstallationVhe', ['validation_master_installation']),
        ...mapFields({
            master_installation: 'master_installation',
        }),
    },

    mounted() {
        if (this.$route.params.id) {
            this.edit = true
        } else {
            this.edit = false
        }
    },

    methods: {
        handleUpdateFields(updatedFields) {
            this.master_installation = {...this.master_installation, ...updatedFields}
        },
        createInstallation() {
            this.$root.$refs.masterInstallationForm.createInstallation()
            this.$router.replace(VHE_MASTERS_INSTALLATIONS_LIST)
        },
        updateInstallation() {
            this.$root.$refs.masterInstallationForm.updateInstallation()
        },

    },

}
</script>
<template>
    <div>
        <b-form-group :label="customLabel" label-for="level_2_id">
            <v-select
                v-model="level2id"
                class="pt-0" label="name" name="name"
                :reduce="(option) => option.id"
                :options="levels2List"
                :disabled="disabled"
            />
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
import vSelect from "vue-select"

export default {
    components: {
        BFormGroup,
        vSelect,
    },
    props: {
        //eslint-disable-next-line vue/prop-name-casing
        level2id: {
            type: Number,
            default: null
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        customLabel: {
            type: String,
            default: "Nível 2"
        }
    },
    data() {
        return {
            levels2List: [],
            localLevel2Id: this.level2id,
        }
    },
    watch: {
        level2id(newVal) {
            this.localLevel2Id = newVal
            this.$emit('update', { level_2_id: this.localLevel2Id })
        },
    },
    mounted() {
        this.getLevels2()
    },
    methods:{
        getLevels2() {
            this.showLoading = true

            this.$store.dispatch('level2Vhe/getAllLevels2')
                .then(response => {
                    this.levels2List = response

                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
    },
}
</script>
<template>
    <div>
        <b-form-group label="País" label-for="installation_type">
            <v-select
                v-model="countryId"
                class="pt-0" label="country" name="country"
                :reduce="(option) => option.country_id"
                :options="countries"
                :disabled="disabled"
            />
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
import vSelect from "vue-select"
import {mapState} from "vuex"
import {mapFields} from "vuex-map-fields"

export default {
    components: {
        BFormGroup,
        vSelect,
    },
    props: {
        countryId: {
            type: Number,
            default: null
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localCompany: this.countryId,
        }
    },
    computed: {
        ...mapState('provincesCountriesVhe', []),
        ...mapFields({
            countries: 'provincesCountriesVhe.countries',
        }),
    },
    watch: {
        countryId: function (id, oldCountryId) {
            this.localCountryId = this.countryId
            this.$emit('update', {country_id: this.localCountryId})
            if (id) {
                this.getAllProvincesCountries(id)
            }
        },
    },
    mounted() {
        this.getAllCountries()
    },
    methods: {
        getAllCountries() {
            this.showLoading = true

            this.$store.dispatch('provincesCountriesVhe/getAllCountries')
                .then(response => {
                    // this.countriesList = response

                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        getAllProvincesCountries(countryId) {
            this.showLoading = true

            this.$store.dispatch('provincesCountriesVhe/getAllProvincesByCountry', {
                countryId: countryId,
            })
                .then(response => {
                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
    },
}
</script>

<template>
  <div>
    <b-form-group label="Modelo de negocio" label-for="business_model">
      <v-select v-model="businessModel" class="pt-0" label="name" name="business_model" :reduce="(option) => option.id"
                :disabled="disabled" :options="businessModelOptions"/>
      <small v-if="validation" class="text-danger">{{ validation }}</small>
    </b-form-group>
  </div>
</template>

<script>
import {BFormGroup, BFormInput} from 'bootstrap-vue'
import vSelect from "vue-select"

export default {
  components: {
    vSelect,
    BFormGroup,
  },
  props: {
    businessModel: {
      type: String,
      default: null
    },
    validation: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localBusinessModel: this.businessModel,
      businessModelOptions: [
        {id: 'Público', name: 'Público'},
        {id: 'Privado', name: 'Privado'},
      ]
    }
  },
  watch: {
    businessModel(newVal) {
      this.localBusinessModel = newVal
      this.$emit('update', {business_model: this.localBusinessModel})
    },
  },
}
</script>

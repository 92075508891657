<template>
    <div>
        <b-card border-variant="primary" class="p-0">
            <b-row>
                <b-col xl="2" lg="4" cols="6">
                    <installation-active-checkbox :active="master_installation.active" :disabled="isDisabled"
                                                  @update="setActiveUpdateFields"/>
                </b-col>
                <b-col xl="3" lg="4" cols="6">
                    <draft-checkbox :draft="master_installation.draft" :disabled="isDisabled"
                                    @update="setDraftUpdateFields"/>
                </b-col>
                <b-col xl="2" lg="4" cols="6">
                    <include-secure-list-checkbox :active="master_installation.add_safe_list" :disabled="isDisabled"
                                                  @update="handleUpdateFields"/>
                </b-col>

                <b-col xl="2" lg="4" cols="6">
                    <b-form-group label="Valor Asegurado" label-for="money">
                        <money v-model="master_installation.insured_value" class="form-control" :disabled="isDisabled"/>
                    </b-form-group>
                </b-col>


                <!--                <b-col xl="3" lg="4">
                                    <company-input :company-id="master_installation.company_id" :disabled="isDisabled"
                                                   :validation="validation_master_installation.company_id"
                                                   @update="handleUpdateFields"/>
                                </b-col>-->
                <!--                <b-col xl="3" lg="4">
                                    <proceeding-code-input :proceeding-code="master_installation.proceeding_code" :disabled="isDisabled"
                                                           :validation="validation_master_installation.proceeding_code"
                                                           @update="handleUpdateFields"/>
                                </b-col>-->
            </b-row>
        </b-card>

        <b-card border-variant="primary">
            <b-row xl="12">
                <b-col xl="4">
                    <client-input :client="master_installation.client" :disabled="isDisabled"
                                  :validation="validation_master_installation.client" @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <client-cif-input :client-cif="master_installation.client_cif" :disabled="isDisabled"
                                      :validation="validation_master_installation.client_cif"
                                      @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <or-input :or="master_installation.or_number" :disabled="isDisabled"
                              :validation="validation_master_installation.or"
                              @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <installation-type-id-select :installation-type-id="master_installation.installation_type_id"
                                                 :disabled="isDisabled"
                                                 :validation="validation_master_installation.installation_type_id"
                                                 @update="handleUpdateFields"/>
                </b-col>
            </b-row>
            <b-row xl="12">
                <b-col xl="2">
                    <date-start-flat-pickr :date-start="master_installation.start_date" :disabled="isDisabled"
                                           :validation="validation_master_installation.start_date"
                                           @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <date-end-flat-pickr :date-end="master_installation.end_date" :disabled="isDisabled"
                                         :validation="validation_master_installation.end_date"
                                         @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <date-closing-flat-pickr :date-closing="master_installation.closing_date" :disabled="isDisabled"
                                             :validation="validation_master_installation.closing_date"
                                             @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <period-description-input :period-description="master_installation.period_description"
                                              :validation="validation_master_installation.period_description"
                                              @update="handleUpdateFields"/>
                </b-col>

                <b-col xl="3 ">
                    <responsible-id-select :responsible-id="master_installation.responsible_id"
                                           :validation="validation_master_installation.responsible_id"
                                           @update="handleUpdateFields"/>
                </b-col>
            </b-row>

            <b-row xl="12">

                <b-col xl="4">
                    <client-contact-person-input :client-contact-person="master_installation.client_contact_person"
                                                 :validation="validation_master_installation.client_contact_person"
                                                 @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <client-phone-input :client-phone="master_installation.client_phone"
                                        :validation="validation_master_installation.client_phone"
                                        @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <client-email-input :client-email="master_installation.client_email"
                                        :validation="validation_master_installation.client_email"
                                        @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <project-responsible-id-select :project-responsible-id="master_installation.project_responsible_id"
                                                   :validation="validation_master_installation.project_responsible_id"
                                                   @update="handleUpdateFields"/>
                </b-col>
            </b-row>
        </b-card>
        <b-card border-variant="primary">
            <b-row xl="12">
                <b-col xl="2">
                    <level1-id-select :level1id="master_installation.level_1_id"
                                      :validation="validation_master_installation.level_1_id"
                                      @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <level2-id-select :level2id="master_installation.level_2_id"
                                      :validation="validation_master_installation.level_2_id"
                                      @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <level3-id-select :level3id="master_installation.level_3_id"
                                      :validation="validation_master_installation.level_3_id"
                                      @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <level4-id-select :level4id="master_installation.level_4_id"
                                      :validation="validation_master_installation.level_4_id"
                                      @update="handleUpdateFields"/>
                </b-col>
            </b-row>
        </b-card>
        <b-card border-variant="primary">
            <b-row cols="12">
                <b-col xl="3">
                    <client-type-id-input :client-type-id="master_installation.client_type_id"
                                          :validation="validation_master_installation.client_type_id"
                                          @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <city-input :city="master_installation.city" :validation="validation_master_installation.city"
                                @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <country-id-select :country-id="master_installation.country_id"
                                       :validation="validation_master_installation.country_id"
                                       @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <postal-code-input :postal-code="master_installation.postal_code"
                                       :validation="validation_master_installation.postal_code"
                                       @update="handleUpdateFields"/>
                </b-col>
            </b-row>
            <b-row cols="12">
                <b-col xl="3">
                    <province-id-select :province-id="master_installation.province_id"
                                        :country-id="master_installation.country_id"
                                        :validation="validation_master_installation.province_id"
                                        @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <latitude-input :latitude="master_installation.latitude"
                                    :validation="validation_master_installation.latitude" @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <longitude-input :longitude="master_installation.longitude"
                                     :validation="validation_master_installation.longitude"
                                     @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <figure-id-select :figure-id="master_installation.figure_id"
                                      :validation="validation_master_installation.figure_id"
                                      @update="handleUpdateFields"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col xl="12">
                    <address-input :address="master_installation.address"
                                   :validation="validation_master_installation.address" @update="handleUpdateFields"/>
                </b-col>
            </b-row>
        </b-card>
        <b-card border-variant="primary">
            <b-row cols="12">
                <b-col xl="4">
                    <territory-input :territory-opsva-id="master_installation.territory_opsva_id"
                                     :validation="validation_master_installation.territory_opsva_id"
                                     @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="4">
                    <business-model :business-model="master_installation.business_model"
                                    :validation="validation_master_installation.business_model" @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="4">
                    <public-infra :public-infra="master_installation.public_infra"
                                  :validation="validation_master_installation.public_infra" @update="handleUpdateFields"/>
                </b-col>
            </b-row>
        </b-card>
        <b-card border-variant="primary">
            <b-row cols="12">
                <b-col xl="12">
                    <observations-text-area :observations="master_installation.observations"
                                            :validation="validation_master_installation.observations"
                                            @update="handleUpdateFields" />
                </b-col>
            </b-row>
        </b-card>
        <serial-number-form/>

        <b-col class="d-flex justify-content-end">
            <b-button v-if="!edit && can('create_vhe_master')" class="col-xl-2" variant="outline-success" block @click="createInstallation()">
                Crear
            </b-button>
            <b-button v-if="edit && can('edit_vhe_master')" class="col-xl-2" variant="outline-success" block @click="updateInstallation()">
                Guardar
            </b-button>
        </b-col>
    </div>
</template>

<script>
import {BCard, BCol, BRow, BButton, BFormGroup} from 'bootstrap-vue'
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import helper from "@/resources/helpers/helpers"

//components
import installationActiveCheckbox
    from "@/views/vhe/masters_installations/form/formInstallationData/components/installationActiveCheckbox"
import includeSecureListCheckbox
    from "@/views/vhe/masters_installations/form/formInstallationData/components/includeSecureListCheckbox"
import draftCheckbox from "@/views/vhe/masters_installations/form/formInstallationData/components/draftCheckbox"
import companyInput from "@/views/vhe/masters_installations/form/formInstallationData/components/companyInput"
import proceedingCodeInput
    from "@/views/vhe/masters_installations/form/formInstallationData/components/proceedingCodeInput"
import clientInput from "@/views/vhe/masters_installations/form/formInstallationData/components/clientInput"
import clientCifInput from "@/views/vhe/masters_installations/form/formInstallationData/components/clientCifInput"
import installationTypeIdSelect
    from "@/views/vhe/masters_installations/form/formInstallationData/components/installationTypeIdSelect"
import segmentIdSelect from "@/views/vhe/masters_installations/form/formHeader/components/segmentIdSelect"
import dateStartFlatPickr
    from "@/views/vhe/masters_installations/form/formInstallationData/components/dateStartFlatPickr"
import dateEndFlatPickr from "@/views/vhe/masters_installations/form/formInstallationData/components/dateEndFlatPickr"
import dateClosingFlatPickr from "@/views/vhe/masters_installations/form/formInstallationData/components/dateClosingFlatPickr"
import periodDescriptionInput
    from "@/views/vhe/masters_installations/form/formInstallationData/components/periodDescriptionInput"
import responsibleIdSelect
    from "@/views/vhe/masters_installations/form/formInstallationData/components/responsibleIdSelect"
import clientContactPersonInput
    from "@/views/vhe/masters_installations/form/formInstallationData/components/clientContactPersonInput"
import clientPhoneInput from "@/views/vhe/masters_installations/form/formInstallationData/components/clientPhoneInput"
import clientEmailInput from "@/views/vhe/masters_installations/form/formInstallationData/components/clientEmailInput"
import projectResponsibleIdSelect
    from "@/views/vhe/masters_installations/form/formInstallationData/components/projectResponsibleIdSelect"
import clientTypeIdInput from "@/views/vhe/masters_installations/form/formInstallationData/components/clientTypeIdInput"
import cityInput from "@/views/vhe/masters_installations/form/formInstallationData/components/cityInput"
import countryIdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/countryIdSelect"
import postalCodeInput from "@/views/vhe/masters_installations/form/formInstallationData/components/postalCodeInput"
import provinceIdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/provinceIdSelect"
import latitudeInput from "@/views/vhe/masters_installations/form/formInstallationData/components/latitudeInput"
import longitudeInput from "@/views/vhe/masters_installations/form/formInstallationData/components/longitudeInput"
import figureIdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/figureIdSelect"
import addressInput from "@/views/vhe/masters_installations/form/formInstallationData/components/addressInput"
import observationsTextArea
    from "@/views/vhe/masters_installations/form/formInstallationData/components/observationsTextArea"
import level1IdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/level1IdSelect"
import level2IdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/level2IdSelect"
import level3IdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/level3IdSelect"
import level4IdSelect from "@/views/vhe/masters_installations/form/formInstallationData/components/level4IdSelect"
import orInput from "@/views/vhe/masters_installations/form/formInstallationData/components/orInput"

import SerialNumberForm from "@/views/vhe/masters_installations/form/formInstallationData/components/formSerialNumbers"
import territoryInput from "@/views/vhe/masters_installations/form/formInstallationData/components/territoryOPSVA"
import businessModel from "@/views/vhe/masters_installations/form/formInstallationData/components/businessModelInput"
import publicInfra from "@/views/vhe/masters_installations/form/formInstallationData/components/publicInfraInput"


const {mapFields} = createHelpers({
    getterType: 'masterInstallationVhe/getField',
    mutationType: 'masterInstallationVhe/updateField',
})
export default {
    name: "FormView",
    components: {
        BCol,
        BRow,
        BCard,
        BButton,
        BFormGroup,


        //componentsForm
        includeSecureListCheckbox,
        installationActiveCheckbox,
        draftCheckbox,
        // companyInput,
        // proceedingCodeInput,
        clientInput,
        clientCifInput,
        installationTypeIdSelect,
        dateStartFlatPickr,
        dateEndFlatPickr,
        dateClosingFlatPickr,
        periodDescriptionInput,
        level1IdSelect,
        level2IdSelect,
        level3IdSelect,
        level4IdSelect,
        responsibleIdSelect,
        clientContactPersonInput,
        clientPhoneInput,
        clientEmailInput,
        projectResponsibleIdSelect,
        clientTypeIdInput,
        cityInput,
        countryIdSelect,
        postalCodeInput,
        provinceIdSelect,
        latitudeInput,
        longitudeInput,
        figureIdSelect,
        addressInput,
        territoryInput,
        businessModel,
        publicInfra,
        observationsTextArea,
        orInput,

        SerialNumberForm
    },
    data() {
        return {
            SpanishLocale,
            edit: false,
            showLoading: false,
            isDisabled: false,
        }
    },
    computed: {
        ...mapState('masterInstallationVhe', ['validation_master_installation']),
        ...mapFields({
            master_installation: 'master_installation',
        }),
    },
    watch: {
        'master_installation.country_id': function(newCountryId, oldCountryId) {
            if (newCountryId !== oldCountryId) {
                console.log('Watch ' + newCountryId)
                this.getAllProvincesCountries(newCountryId)
            }
        },
    },
    created() {
        if (this.$route.params.id ) {
            this.edit = true
        } else {
            this.edit = false
        }
        this.$root.$refs.formInstallationData = this
        // this.$root.$refs.masterInstallationForm = this
    },

    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        createInstallation() {
            this.$store.dispatch('masterInstallationVhe/createMasterInstallation', this.master_installation)
                .then(response => {
                    //this.master_installation.id = response.id
                    helper.showToast('Instalación padre creada correctamente!', 'CheckIcon', 'success')
                })
                .catch(error => {
                    helper.showToast('Error al crear instalación', 'AlertOctagonIcon', 'danger')
                    console.log(error)
                })
        },
        updateInstallation() {
            this.setProvinceAndCountry(this.master_installation.country_id, this.master_installation.province_id)

            this.$store.dispatch('masterInstallationVhe/updateMasterInstallation', this.master_installation)
                .then(response => {
                    helper.showToast('Instalación padre editada correctamente!', 'CheckIcon', 'success')
                })
                .catch(error => {
                    helper.showToast('Error al editar instalación', 'AlertOctagonIcon', 'danger')
                    console.log(error)
                })
        },
        setProvinceAndCountry(targetCountryId, targetProvinceId) {
            const matchingProvince = this.$store.state.provincesCountriesVhe.provinces.find(province => {
                return province.country_id === targetCountryId && province.province_id === targetProvinceId
            })

            if (matchingProvince) {
                this.master_installation.province_country_id = matchingProvince.id
            }
        },
        handleUpdateFields(updatedFields) {
            this.master_installation = {...this.master_installation, ...updatedFields}
        },
        setActiveUpdateFields(updatedFields){
            this.handleUpdateFields(updatedFields)

            if (this.master_installation.active === 1){
                this.master_installation.draft = 0
            }

        },
        setDraftUpdateFields(updatedFields){
            this.handleUpdateFields(updatedFields)

            if (this.master_installation.draft === 1){
                this.master_installation.active = 0
            }
        },
        getAllProvincesCountries(countryId) {
            this.showLoading = true
            this.master_installation.province_id = null

            this.$store
                .dispatch('provincesCountriesVhe/getAllProvincesByCountry', {
                    countryId: countryId,
                })
                .then(response => {

                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<template>
    <div>
        <b-form-group label="Segmento" label-for="segment">
            <v-select
                v-model="localSegmentId"
                class="pt-0" label="segment" name="segment"
                :reduce="(option) => option.id"
                :options="segments"
                :disabled="disabled"
                @input="handleSegmentChange"
            />
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
import vSelect from "vue-select"
import {mapState} from "vuex"
import {mapFields} from "vuex-map-fields"

export default {
    components: {
        BFormGroup,
        vSelect,
    },
    props: {
        segmentId: {
            type: Number,
            default: null
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },

    },
    data(){
        return{
            segment_code: null
        }

    },
    computed: {
        ...mapState('segmentsVhe', []),
        ...mapFields({
            segments: 'segmentsVhe.segments',
        }),
        localSegmentId: {
            get() {
                // this.handleSegmentChange(this.segmentId)
                return this.segmentId
            },
            set(value) {
                this.handleSegmentChange(value)
                this.$emit('update', { segment_id: value})
            },
        },
    },
    mounted() {
        this.getSegments()
    },
    methods:{
        getSegments() {
            this.showLoading = true

            this.$store.dispatch('segmentsVhe/getAllSegments')
                .then(response => {
                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        handleSegmentChange(selectedSegment) {
            const segment = this.segments.find(seg => seg.id === selectedSegment)
            if (segment) {
                this.segment_code = segment.code
            }
            this.$emit('update', { segment_code: this.segment_code })
        },
    },
}
</script>